import React from 'react';
import './Popup.css';

function Popup({ title, message, onCancel, onConfirm, isError }) {
    console.log('Popup rendered with isError:', isError);

  return (
    <div className="popup-overlay">
        <div className={`popup-container ${isError ? 'error' : ''}`}>
        <h3 className="popup-title">{title}</h3>
        <div className="popup-message">
          {/* Split the message by newline and render each as a paragraph */}
          {message.split('\n').map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </div>
        <div className="popup-actions" >
          {isError ? (
            <button className='confirm' onClick={onCancel || onConfirm}>Understood</button> // Single button for error popup
          ) : (
            <>
              {onConfirm && <button className='confirm' onClick={onConfirm}>&#10003; Confirm</button>}
              {onCancel && <button className='cancel'  onClick={onCancel}>Cancel</button>}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Popup;
