// src/components/EmotionPlayer/EmotionPlayer.js

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { throttle } from 'lodash';
import './EmotionPlayer.css';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import PlutchikWheel from '../PlutchikWheel/PlutchikWheel'; // Import the Plutchik Wheel component
import plutchikMapping from '../PlutchikWheel/plutchikMapping'; // Import the emotion mapping
import { categories, getSpeakerColorScale, getSpeakerColor, speakerBaseColors } from '../../constants'; // Ensure correct path

// // Centralize categories in EmotionPlayer.js
// const categories = ['Happiness', 'Curiosity-Anticipation', 'Calmness', 'Boredom', 'Sadness', 'Disgust', 'Anger-Fear', 'Surprise'];




function EmotionPlayer({ uniqueId, predictions, topCategoryEmotions, token }) {

  console.log(uniqueId)
  console.log('EmotionPlayer mounted');

  // const [predictions, setPredictions] = useState([]);
  const [currentText, setCurrentText] = useState('');
  const [currentEmotions, setCurrentEmotions] = useState([]);
  const [currentTopCategoryEmotions, setCurrentTopCategoryEmotions] = useState([]);
  const [audioSrc, setAudioSrc] = useState(null);
  const [audioDuration, setAudioDuration] = useState(0);
  const [audioCurrentTime, setAudioCurrentTime] = useState(0); // Current time of the audio
  const [activeSpeaker, setActiveSpeaker] = useState(null); // Define activeSpeaker as state
  // const token = 'eyJhbGciOiJIUzI1NiIsImtpZCI6ImxIdDRlU25SNnU1MXhyRVciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3JicmJsc2J2cGl4aHZnd3Fsaml5LnN1cGFiYXNlLmNvL2F1dGgvdjEiLCJzdWIiOiI5NDA2MGEwMC1jMmE2LTQwNGYtOTkzNC1iZTU2YmRlNjIxMTIiLCJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNzMyMTIxNzgxLCJpYXQiOjE3MzIxMTE3ODEsImVtYWlsIjoiYXJuYXVkLmNpc3NlQG9yYW5nZS5mciIsInBob25lIjoiIiwiYXBwX21ldGFkYXRhIjp7InByb3ZpZGVyIjoiZW1haWwiLCJwcm92aWRlcnMiOlsiZW1haWwiXX0sInVzZXJfbWV0YWRhdGEiOnsiZW1haWwiOiJhcm5hdWQuY2lzc2VAb3JhbmdlLmZyIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmllZCI6ZmFsc2UsInN1YiI6Ijk0MDYwYTAwLWMyYTYtNDA0Zi05OTM0LWJlNTZiZGU2MjExMiJ9LCJyb2xlIjoiYXV0aGVudGljYXRlZCIsImFhbCI6ImFhbDEiLCJhbXIiOlt7Im1ldGhvZCI6Im90cCIsInRpbWVzdGFtcCI6MTczMjExMTc4MX1dLCJzZXNzaW9uX2lkIjoiNzgwMzk1ZDctYzY1Ny00NzdkLWFhNDgtNGExOGRmZmEyY2JlIiwiaXNfYW5vbnltb3VzIjpmYWxzZX0.l0GKjEVPmnVJtOe_DMTHYSi5au9p6sFZ4e7M3gevuP8';

  

  const audioRef = useRef(null); // Reference to the audio element
  const timelineRef = useRef(null); // Reference to the timeline track
  const isDraggingRef = useRef(false); // Track if the user is dragging the line

  const trimmedUniqueId = uniqueId ? uniqueId.trim() : '';
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5001';
  const audioUrl = `${backendUrl}/uploads/${trimmedUniqueId}/audios/combined_audio.wav`;


  // Fetch the audio file with the Authorization header
  useEffect(() => {

    let url; // Local variable to store the Blob URL

    const fetchAudio = async () => {
      try {
        // Get the access token from Supabase authentication
        // const token = 'eyJhbGciOiJIUzI1NiIsImtpZCI6ImxIdDRlU25SNnU1MXhyRVciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3JicmJsc2J2cGl4aHZnd3Fsaml5LnN1cGFiYXNlLmNvL2F1dGgvdjEiLCJzdWIiOiI5NDA2MGEwMC1jMmE2LTQwNGYtOTkzNC1iZTU2YmRlNjIxMTIiLCJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNzMyMTIxNzgxLCJpYXQiOjE3MzIxMTE3ODEsImVtYWlsIjoiYXJuYXVkLmNpc3NlQG9yYW5nZS5mciIsInBob25lIjoiIiwiYXBwX21ldGFkYXRhIjp7InByb3ZpZGVyIjoiZW1haWwiLCJwcm92aWRlcnMiOlsiZW1haWwiXX0sInVzZXJfbWV0YWRhdGEiOnsiZW1haWwiOiJhcm5hdWQuY2lzc2VAb3JhbmdlLmZyIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmllZCI6ZmFsc2UsInN1YiI6Ijk0MDYwYTAwLWMyYTYtNDA0Zi05OTM0LWJlNTZiZGU2MjExMiJ9LCJyb2xlIjoiYXV0aGVudGljYXRlZCIsImFhbCI6ImFhbDEiLCJhbXIiOlt7Im1ldGhvZCI6Im90cCIsInRpbWVzdGFtcCI6MTczMjExMTc4MX1dLCJzZXNzaW9uX2lkIjoiNzgwMzk1ZDctYzY1Ny00NzdkLWFhNDgtNGExOGRmZmEyY2JlIiwiaXNfYW5vbnltb3VzIjpmYWxzZX0.l0GKjEVPmnVJtOe_DMTHYSi5au9p6sFZ4e7M3gevuP8';
        if (!token) {
          console.error('No access token found');
          // Handle error appropriately
          return;
        }

        // Fetch the audio file with the Authorization header
        console.log('run the uploads audio thingy')
        const response = await fetch(audioUrl, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          console.error('Failed to fetch audio file');
          return;
        }

        // Convert the response into a Blob
        const blob = await response.blob();

        // Create a Blob URL and set it as the audio source
        const url = URL.createObjectURL(blob);
        setAudioSrc(url);
      } catch (error) {
        console.error('Error fetching audio file:', error);
      }
    };

    fetchAudio();

    // Cleanup the Blob URL when the component unmounts or when audioSrc changes
    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [uniqueId]);
  
  // Throttled function to update the visualization with current emotions
  const throttledUpdateVisualization = useRef(
    throttle((prediction, topCategoryEmotion) => {
      // Since backend already filters and aggregates, directly set the emotions
      // setCurrentEmotions(prediction.emotions || []);
      console.log(topCategoryEmotion)
      setCurrentTopCategoryEmotions(topCategoryEmotion || []);
    }, 100)
  ).current;


  // Handle the audio time update, call visualization update when the time changes
  const handleTimeUpdate = () => {
    
    const audioElement = audioRef.current;
    const currentTime = audioElement.currentTime;
    const duration = audioElement.duration;

    setAudioCurrentTime(currentTime);
    setAudioDuration(duration);

  // Ensure that predictions[0] exists and is an array
  const globalPredictions = predictions[0] || [];

  // Find the current prediction from global predictions
  const currentPrediction = globalPredictions.find((pred) => {
    return currentTime >= pred.begin && currentTime <= pred.end;
  });

  // Initialize variables for active speaker and their emotion data
  let tempActiveSpeaker = null;
  let currentTopCategoryEmotion = null;

  // Iterate through speakers (excluding "all") to find the active speaker at currentTime
  for (let i = 1; i < topCategoryEmotions.length; i++) { // Start from index 1 to skip "all"
    const speakerIntervals = topCategoryEmotions[i];
    console.log(i)
    if (!speakerIntervals || !Array.isArray(speakerIntervals)) continue;
    
    // Find if the currentTime falls within any interval for this speaker and has non-empty categories
    const speakerInterval = speakerIntervals.find((tce) => {
      const inInterval = currentTime >= tce.begin && currentTime <= tce.end;
      const hasCategories = tce.categories && tce.categories.length > 0;
      return inInterval && hasCategories;
    });

    if (speakerInterval) {
      tempActiveSpeaker = speakerInterval.speaker;
      console.log("speakerinterval:", speakerInterval);
      currentTopCategoryEmotion = speakerInterval.categories; // This is an array
      console.log(`Active Speaker Found: ${activeSpeaker}, Categories:`, currentTopCategoryEmotion);
      break; // Assume only one speaker is active at a time
    }
  }

  console.log("Temp Active Speaker:", tempActiveSpeaker);
  console.log("Current Prediction:", currentPrediction);
  console.log("Current Top Category Emotion:", currentTopCategoryEmotion);

  if (currentPrediction && currentTopCategoryEmotion) {
    setCurrentText(currentPrediction.text || '');
    setActiveSpeaker(tempActiveSpeaker); // Update the state with the active speaker
    throttledUpdateVisualization(currentPrediction, currentTopCategoryEmotion);
  } else {
    setCurrentText('');
    setActiveSpeaker(null); // Reset activeSpeaker if no active speaker found
    setCurrentEmotions([]); // Ensure this state exists or remove if unused
    setCurrentTopCategoryEmotions([]);
  }
};


  // Calculate the position of the vertical line
  const calculateLinePosition = () => {
    if (!timelineRef.current || audioDuration === 0) return 0;
    const timelineWidth = timelineRef.current.offsetWidth;
    return (audioCurrentTime / audioDuration) * timelineWidth;
  };

  const calculateBoxPositionAndWidth = (interval) => {
    if (!timelineRef.current || audioDuration === 0) {
      return { left: 0, width: 0 };
    }
  
    const timelineWidth = timelineRef.current.offsetWidth;
    const beginRatio = interval.begin / audioDuration;
    const endRatio = interval.end / audioDuration;
    const left = beginRatio * timelineWidth;
    const width = (endRatio - beginRatio) * timelineWidth;
  
    return { left, width };
  };
    

  // Handle dragging
  const handleDragStart = (event) => {
    event.preventDefault(); // Prevent default behavior like text selection
    isDraggingRef.current = true;
    moveVerticalLine(event);
  };

  const handleDragMove = throttle((event) => {
    if (!isDraggingRef.current) return;
    moveVerticalLine(event);
  }, 20); // Throttle delay set to 20ms for smoother dragging

  const handleDragEnd = () => {
    if (!isDraggingRef.current) return;
    isDraggingRef.current = false;
  };

  const moveVerticalLine = (event) => {
    if (!timelineRef.current) return;

    const timelineRect = timelineRef.current.getBoundingClientRect();
    const timelineWidth = timelineRef.current.offsetWidth;
    let newPosition = event.clientX - timelineRect.left;

    // Clamp the new position to stay within the bounds of the timeline
    newPosition = Math.max(0, Math.min(newPosition, timelineWidth));

    const newTime = (newPosition / timelineWidth) * audioDuration;

    if (newTime >= 0 && newTime <= audioDuration) {
      setAudioCurrentTime(newTime);
      if (audioRef.current) {
        audioRef.current.currentTime = newTime;
      }
    }
  };


  const getCategoryIntervals = (topCategoryEmotionsArray) => {
    console.log(topCategoryEmotionsArray)
    const categoryIntervals = {}; // key: category, value: array of intervals

    // console.log(audioDuration)
  
    // Initialize categoryIntervals
    categories.forEach((category) => {
      categoryIntervals[category] = [];
    });
    
  // Iterate over each speaker's array in topCategoryEmotionsArray
  topCategoryEmotionsArray.slice(1).forEach((speakerIntervals) => {
    if (!speakerIntervals || !Array.isArray(speakerIntervals)) return; // Safety check

    speakerIntervals.forEach((intervalObj) => {
      const speaker = intervalObj.speaker;
      const timeBegin = intervalObj.begin;
      const timeEnd = intervalObj.end;

      // Safety check for categories
      if (!intervalObj.categories || !Array.isArray(intervalObj.categories)) return;

      // Iterate over each emotion in the categories array of the interval
      intervalObj.categories.forEach((emotionObj) => {
        const category = emotionObj.category; // e.g., 'Happiness'
        const emotion = emotionObj.emotion;   // e.g., 'Joy'
        const score = emotionObj.score;
        const time = emotionObj.time || { begin: timeBegin, end: timeEnd }; // Use time from emotionObj or interval

        const interval = {
          category,
          emotion,
          score,
          begin: time.begin,
          end: time.end,
          speaker: speaker // Include speaker information
        };

        categoryIntervals[category].push(interval);
      });
    });
  });

  return categoryIntervals;
};
  
  const categoryIntervals = useMemo(
    () => getCategoryIntervals(topCategoryEmotions),
    [topCategoryEmotions, audioDuration]
  );

  // Extract unique speakers from topCategoryEmotions (excluding "all")
  const speakers = useMemo(() => {
    const speakerSet = new Set();
    topCategoryEmotions.slice(1).forEach(speakerIntervals => { // Start from index 1 to skip "all"
      if (Array.isArray(speakerIntervals)) {
        speakerIntervals.forEach(intervalObj => {
          if (intervalObj.speaker && intervalObj.speaker !== 'all') {
            speakerSet.add(intervalObj.speaker);
          }
        });
      }
    });
    return Array.from(speakerSet);
  }, [topCategoryEmotions]);
  

  

  return (
    <div className="emotion-player-container">

      {/* Check if predictions and topCategoryEmotions have data */}
      {(!predictions || predictions.length === 0) || (!topCategoryEmotions || topCategoryEmotions.length === 0) ? (
        <div>Loading emotions...</div>
      ) : (
        <>
          <div className="audio-text">
            {/* Audio Section */}
            <div className="audio-section">
              <h3>Audio Player</h3>
              <audio
                ref={audioRef}
                controls
                src={audioSrc}  // Use the Blob URL instead
                className="audio-player"
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={() => {
                  if (audioRef.current) {
                    setAudioDuration(audioRef.current.duration);
                    console.log('Audio metadata loaded, duration:', audioRef.current.duration);
                  }
                }}
              
              />
            </div>

            {/* Current Text Section */}
            <div className="current-text-container">
              <h3>Text identified in the audio</h3>
              <div className='current-text'>
                <p 
                  style={{
                    fontWeight:'normal',
                    color: getSpeakerColor(activeSpeaker)
                  }}
                >
                  {activeSpeaker}
                </p>
                <p>{currentText}</p>
              </div>
              
            </div>
          </div>

          {/* Visualization and Emotions Section */}
          <div className="visualization-emotions-container">
            
            <div className='emotion-title-container'>
              <h3>Timeline analysis</h3>

              <div className="speaker-legend">
                {speakers.map((speaker, index) => (
                  <div key={index} className="legend-item">
                    <span
                      className="legend-color-box"
                      style={{ backgroundColor: speakerBaseColors[speaker] }}
                    ></span>
                    <span className="legend-speaker-name">
                      {speaker.replace('_', ' ').toUpperCase()}
                    </span>
                  </div>
                ))}
              </div>
            </div>


            <div className="visualization-emotions-container2">
              {/* Speaker Legend */}

              {/* Emotion Labels and Single Timeline */}
              <div className="emotion-timeline-container">
                {/* Emotion Labels */}
                <div className="emotion-labels">
                  {categories.map((category, index) => (
                    <div key={index} className="emotion-row">
                      <span className="emotion-label">{category}</span>
                    </div>
                  ))}
                </div>

                {/* Single Timeline Track */}
                <div
                  className="timeline-track"
                  ref={timelineRef}
                  onMouseDown={handleDragStart}
                  onMouseMove={handleDragMove}
                  onMouseUp={handleDragEnd}
                  onMouseLeave={handleDragEnd}
                  onTouchStart={(e) => handleDragStart(e.touches[0])}
                  onTouchMove={(e) => handleDragMove(e.touches[0])}
                  onTouchEnd={handleDragEnd}
                  onTouchCancel={handleDragEnd}
                >

                  {/* Horizontal Separators */}
                    {categories.map((category, index) => {
                    if (index < categories.length -1) { // Only add separators between rows
                      return (
                        <div
                          key={`separator-${index}`}
                          className="row-separator"
                          style={{ top: `${index/2 + (index +1) * 40}px` }} // Assuming each row is 40px high
                        />
                      );
                    }
                    return null;
                  })}

                  {/* Vertical line moving within the timeline */}
                  <div
                    className="timeline-vertical-line"
                    style={{ left: `${calculateLinePosition()}px` }}
                  />

                  {/* Render boxes for each emotion */}
                  {categories.map((category, catIndex) =>
                    categoryIntervals[category].map((interval, index) => {
                      const { left, width } = calculateBoxPositionAndWidth(interval);
                      const colorScale = getSpeakerColorScale(interval.speaker); // Get the color scale for the speaker
                      const color = colorScale(interval.score); // Map the emotion score to a color

                      return (
                        <div
                          key={`${category}-${index}`}
                          className="emotion-box"
                          style={{
                            left: `${left + 1}px`,
                            width: `${width}px`,
                            top: `${5 +catIndex/2 + catIndex * 40}px`,
                            backgroundColor: color,
                          }}
                        >
                          {/* Optionally display emotion name or score */}
                          {interval.emotion}
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              <div className='plutchik-container'>
                {/* Plutchik Wheel for Current Emotions */}
                <PlutchikWheel 
                  topCategoryEmotions={currentTopCategoryEmotions} 
                  speaker={activeSpeaker} 
                  textSize={0.045}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default EmotionPlayer;
