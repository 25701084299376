// src/components/AuthCallback.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../../supabaseClient';

const AuthCallback = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('Signing you in...');
  const [error, setError] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5001';


  const createUserRecord = async (token, username) => {
    try {
      // console.log(token)
      const response = await fetch(`${backendUrl}/create_user_record`, { // Replace with your backend URL
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log('User record created:', data.message);
      } else {
        console.error('Failed to create user record:', data.error);
      }
    } catch (err) {
      console.error('Error creating user record:', err);
    }
  };

  useEffect(() => {
    // The magic link will automatically set up the session
    const handleRedirect = async () => {

      const hash = window.location.hash.substring(1);
      const hashParams = new URLSearchParams(hash);
      const error = hashParams.get('error');
      const errorDescription = hashParams.get('error_description');


      if (error) {
        // If there's an error in the URL, redirect to home with error message
        const decodedError = errorDescription
          ? decodeURIComponent(errorDescription.replace(/\+/g, ' '))
          : 'Authentication failed.';
        // Navigate to home with error message as query parameter
        navigate(`/?error=${encodeURIComponent(decodedError)}`);
        return;
      }
    

      try {
        // Get the session - at this point it should be automatically set up
        const { data: { session }, error } = await supabase.auth.getSession()
        // console.log('Access Token:', session?.access_token);
        
        if (error) throw error
        
        if (session) {
          const token = session?.access_token
          const email = session.user.email; // Get the user's email
          await createUserRecord(token, email); // Create the user record in the database
          // Pass the token to the Dashboard component
          navigate('/dashboard', { state: { token } });
        } else {
          navigate('/?error=Authentication failed. Please try again.');
        }
  
      } catch (error) {
        console.error('Error:', error.message)
        setError(error.message)
        // Handle failed authentication
        navigate(`/?error=${encodeURIComponent(error.message)}`);
      }
    }

    handleRedirect()
}, [navigate]);

return (
    <div>
      {error && (
        <div className="popup-error">
          {error}
        </div>
      )}
      <p>Signing you in...</p>
    </div>
  );
};

export default AuthCallback;
