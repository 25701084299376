import './Navbar.css'
import { useNavigate, Link } from 'react-router-dom';
import supabase from '../../supabaseClient';
import React from 'react';



function Navbar({ user, onLogout }) {

  // console.log(user);


  return (
    <div className="navbar">
      <div className="navbar-logo">
        <Link to="/">YourLogo</Link>
      </div>
      <div className="navbar-links">
        {user ? (
          <>
            <span className='username'>{user.email}</span> {/* Display user email */}
            <div 
              className="credits-circle"
              title="User credits you can use" // Default browser tooltip
            >
              {user.credits} credits
            </div>
            <button className="logout-button" onClick={onLogout}>
              Logout
            </button>
          </>
        ) : null} 
      </div>
    </div>
  );
}

export default Navbar;
