import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import Navbar from '../Navbar/Navbar'; // Adjust the import path as necessary
import Login from '../Login/Login';
import './Landing.css';
import {pic} from '../../assets/landing_pic.png'

function Landing() {
    const [buttonText, setButtonText] = useState("Continue");
    const [isDisabled, setIsDisabled] = useState(false);
    const [timer, setTimer] = useState(5);
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();
  
    useEffect(() => {
        // Extract error message from URL query parameters
        const params = new URLSearchParams(location.search);
        const error = params.get('error');
        if (error) {
          setErrorMessage(decodeURIComponent(error));
          // Clear error message from URL after displaying it
          window.history.replaceState({}, document.title, '/');
        }
      }, [location]);
    

    const handleContinue = () => {
        setButtonText("Click the link sent to your email");
        setIsDisabled(true);
        setTimer(10); // Reset timer to 5 seconds
    };

    useEffect(() => {
        let interval = null;
        if (isDisabled && timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setIsDisabled(false);
            setButtonText("Continue");
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isDisabled, timer]);

    return (
        <>
            <Navbar />
            <div className="landing-container">
                {errorMessage && (
                <div className="popup-error">
                    {errorMessage}
                </div>
                )}
                <div className='element-container'>
                    <div className="content-container">
                        <h1 className="main-title">What do you make people feel?</h1>
                        <p>Upload any video or audio recording</p>
                        <p>Analyse each speaker's emotion</p>
                        <p>Keep track of your uploads</p>
                    </div>
                    <Login /> {/* Include the Login component here */}
                </div>
            </div>
        </>
    );
}

export default Landing;
