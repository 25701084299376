import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import FileUpload from './components/FileUpload/FileUpload';
// import EmotionPlayer from './components/EmotionPlayer/EmotionPlayer';
import Dashboard from './components/Dashboard/Dashboard';
import AuthCallback from './components/AuthCallback/AuthCallback';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'; // We'll create this later
// import Navbar from './components/Navbar/Navbar';
import Landing from './components/Landing/Landing'; // Adjust the import path as necessary


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        {/* <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/dashboard"
          element={
            <Dashboard />
          }
        />

      </Routes>
    </Router>
  );
}

export default App;
