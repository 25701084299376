import * as d3 from 'd3';

export const categories = [
  'Happiness',
  'Curiosity-Anticipation',
  'Calmness',
  'Boredom',
  'Sadness',
  'Disgust',
  'Anger-Fear',
  'Surprise'
];

export const speakerColors = {
  'all': '#1f77b4',       // Blue for global speaker
  'speaker_00': '#ff7f0e', // Orange for speaker_00
  'speaker_01': '#2ca02c', // Green for speaker_01
  'speaker_02': '#d62728', // Red for speaker_02
  'speaker_03': '#9467bd', // Purple for speaker_03
  'other': '#8c564b'       // Brown for other speakers
};


export const colorScale = d3.scalePow()
    .exponent(0.5) // Adjust exponent <1 for emphasizing lower scores
    .domain([0, 1]) // Assuming scores range between 0 and 1
    .range(['#f0f0f0', '#003f5c']); // Light grey to dark blue


    export const speakerBaseColors = {
      'all': '#333333',        // Darker grey for global speaker
      'speaker_00': '#cc6666', // Darker red for speaker_00
      'speaker_01': '#66cc66', // Darker green for speaker_01
      'speaker_02': '#6666cc', // Darker blue for speaker_02
      'speaker_03': '#cc66cc', // Darker purple for speaker_03
      'other': '#cccc66'       // Darker yellow for global speaker
    };
        

export const getSpeakerColorScale = (speaker) => {
  const baseColor = speakerBaseColors[speaker] || speakerBaseColors['other'];
  
  return d3.scalePow()
    .exponent(0.5) // Adjust exponent as needed for color interpolation
    .domain([0, 1]) // Assuming emotion scores range from 0 to 1
    .range(['#f0f0f0', baseColor]); // Light grey to speaker's base color
};

export const getSpeakerColor = (speaker) => {
  // Return the color if it exists, otherwise return a default color
  return speakerBaseColors[speaker] || '#000000'; // Default to black if speaker not found
};


