import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import './PlutchikWheel.css';
import { categories, speakerBaseColors, getSpeakerColorScale } from '../../constants';

function PlutchikWheel({ topCategoryEmotions, speaker, size = 300, textSize }) { 
  const svgRef = useRef(null);
  const tooltipRef = useRef(null);

  // Initialize the Plutchik Wheel on mount
  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    const width = size;
    const height = size;
    const centerX = width / 2;
    const centerY = height / 2;
    const outerRadius = size / 2 - 40; // Adjusted radius based on size
    const innerRadius = 0;

    svg.selectAll('*').remove(); // Clear previous content

    // Create arc generator for the slices
    const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

    // Create pie generator for the 8 categories
    const pie = d3.pie().sort(null).value(() => 1); // Equal slices for all categories

    // Create data for the pie
    const pieData = pie(categories);

    // Draw the axes behind the wheel
    svg
      .append('line')
      .attr('x1', 0)
      .attr('y1', centerY)
      .attr('x2', width)
      .attr('y2', centerY)
      .attr('stroke', 'black')
      .attr('stroke-dasharray', '5,5') // Dotted line
      .attr('stroke-width', 1)
      .lower(); // Ensure this line is below the wheel

    svg
      .append('line')
      .attr('x1', centerX)
      .attr('y1', 0)
      .attr('x2', centerX)
      .attr('y2', height)
      .attr('stroke', 'black')
      .attr('stroke-dasharray', '5,5') // Dotted line
      .attr('stroke-width', 1)
      .lower(); // Ensure this line is below the wheel

      const quadrantLabels = [
        {
          text: '+ Valence\nHigh Arousal',
          x: centerX + outerRadius * 0.92,
          y: centerY - outerRadius * 0.92,
        },
        {
          text: '- Valence\nHigh Arousal',
          x: centerX - outerRadius * 0.92,
          y: centerY - outerRadius * 0.92,
        },
        {
          text: '- Valence\nLow Arousal',
          x: centerX - outerRadius * 0.92,
          y: centerY + outerRadius * 0.92,
        },
        {
          text: '+ Valence\nLow Arousal',
          x: centerX + outerRadius * 0.92,
          y: centerY + outerRadius * 0.92,
        },
      ];
  
      quadrantLabels.forEach((label) => {
        svg
          .append('text')
          .attr('x', label.x)
          .attr('y', label.y)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('class', 'quadrant-label')
          .attr('fill', 'black')
          .attr('font-size', `${size * textSize}px`) // Adjust font size as needed
          .selectAll('tspan')
          .data(label.text.split('\n'))
          .enter()
          .append('tspan')
          .attr('x', label.x)
          .attr('dy', (d, i) => (i === 0 ? '-0.6em' : '1.2em')) // Position first line up, second line down
          .text((d) => d);
      });
  

    // Draw slices with a 22.5-degree rotation
    svg
      .selectAll('path')
      .data(pieData)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', 'EAE9E9')
      .attr('stroke', 'white')
      .attr('transform', `translate(${centerX}, ${centerY}) rotate(22.5)`)
      .attr('class', (d, i) => `category-slice ${categories[i].toLowerCase().replace(/ /g, '-')}-slice`);

    // Add labels for categories
    svg
      .selectAll('text.category-label')
      .data(pieData)
      .enter()
      .append('text')
      .attr('transform', function (d) {
        const [x, y] = arc.centroid(d);
        // Scale the position to move the labels outward
        const labelRadius = 1.2; // Adjust this value to move the labels further away (0.6 is 60% of the radius)
        const scaledX = x * labelRadius;
        const scaledY = y * labelRadius;
    
        // Adjust the position to account for rotation
        const angle = 22.5 * (Math.PI / 180);
        const rotatedX = scaledX * Math.cos(angle) - scaledY * Math.sin(angle);
        const rotatedY = scaledX * Math.sin(angle) + scaledY * Math.cos(angle);
        return `translate(${centerX + rotatedX}, ${centerY + rotatedY})`;
          })
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('class', 'category-label')
      .text((d, i) => categories[i])
      .attr('fill', 'white')
      .attr('font-size', `${size * textSize}px`); // Dynamic font size based on wheel size
  }, []); // Run once on mount

  // Update slices and re-bind event handlers when topCategoryEmotions changes
  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);

    // console.log("Top Category Emotions:", topCategoryEmotions); 

    // Get the color scale for the current speaker
    const colorScale = getSpeakerColorScale(speaker);


    categories.forEach((category) => {
      const categoryLower = category.toLowerCase().replace(/ /g, '-');
      const slice = svg.selectAll(`.${categoryLower}-slice`);
      // console.log(categor)

      // Find the top emotion for this category
      const emotionObj = topCategoryEmotions.find(
        (emotionObj) => emotionObj.category.toLowerCase().replace(/ /g, '-') === categoryLower
      );
      // console.log(`Category: ${category}, Emotion Object:`, emotionObj); 

      if (emotionObj) {
        const score = emotionObj.score;

        // Compute the color based on the emotion score using the speaker's color scale
        const color = colorScale(score);


        slice
          .transition()
          .duration(300)
          .attr('fill', color || 'EAE9E9');

        // Bind event handlers with the latest topCategoryEmotions
        slice
          .on('mouseover', function (event) {
            const tooltip = d3.select(tooltipRef.current);
            const { emotion, score } = emotionObj;
            const tooltipContent = `<strong>${emotion}</strong>: ${score.toFixed(3)}<br/>`;

            tooltip
              .style('visibility', 'visible')
              .html(tooltipContent)
              .style('left', `${event.pageX + 10}px`)
              .style('top', `${event.pageY - 10}px`);
          })
          .on('mousemove', function (event) {
            const tooltip = d3.select(tooltipRef.current);
            tooltip.style('left', `${event.pageX + 10}px`).style('top', `${event.pageY - 10}px`);
          })
          .on('mouseout', function () {
            const tooltip = d3.select(tooltipRef.current);
            tooltip.style('visibility', 'hidden');
          });
      } else {
        slice
          .transition()
          .duration(300)
          .attr('fill', '#D9D9D9');

        // Remove event handlers for slices without emotions
        slice.on('mouseover', null).on('mousemove', null).on('mouseout', null);
      }
    });
  }, [topCategoryEmotions, speaker, size]); // Added 'size' as a dependency

  return (
    <>
      <svg
        ref={svgRef}
        className="plutchik-wheel"
        viewBox={`0 0 ${size} ${size}`}
        preserveAspectRatio="xMidYMid meet"
      ></svg>
      <div
        ref={tooltipRef}
        className="tooltip"
        style={{
          position: 'absolute',
          visibility: 'hidden',
          backgroundColor: 'rgba(255,255,255,1)',
          border: '1px solid #d3d3d3',
          padding: '5px',
          borderRadius: '4px',
          pointerEvents: 'none',
          boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
          zIndex: 1000,
          fontSize: '14px',
        }}
      ></div>
    </>
  );
}

export default PlutchikWheel;
