// src/components/Dashboard/Dashboard.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FileUpload from '../FileUpload/FileUpload';
import FileDropdown from '../FileDropdown/FileDropdown';
import EmotionPlayer from '../EmotionPlayer/EmotionPlayer';
import PlutchikWheel from '../PlutchikWheel/PlutchikWheel'; // Import PlutchikWheel
import Navbar from '../Navbar/Navbar';
import supabase from '../../supabaseClient'; // Import Supabase client
import Popup from '../Popup/Popup';
import './Dashboard.css';

function Dashboard() {
  const [isMobile, setIsMobile] = useState(false); // State to track if user is on mobile
  const [userFiles, setUserFiles] = useState([]); // Store file records
  const [selectedFile, setSelectedFile] = useState(null);
  const [averageScoresCategory, setAverageScoresCategory] = useState([]);
  const [averageScoresEmotion, setAverageScoresEmotion] = useState([]);
  const [user, setUser] = useState({email:'arnaud.cisse@orange.fr'});
  const [predictions, setPredictions] = useState([]); // New state for predictions
  const [topCategoryEmotions, setTopCategoryEmotions] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [isRenaming, setIsRenaming] = useState(false);
  const [showCreditPopup, setShowCreditPopup] = useState(false);
  const [fileDuration, setFileDuration] = useState(0); // File duration for analysis
  const [selectedNewRecording, setSelectedNewRecording] = useState(null); // For file to be uploaded
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState({
    title: '',
    message: '',
    isError: false,
    onConfirm: null,
  });
  const [userCredits, setUserCredits] = useState(''); // User's credits
  const [isPopupError, setIsPopupError] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    'Saving the audio file...',
    'Performing speaker diarization...',
    'Performing emotion analysis...',
    'Uploading predictions...',
  ];

  const [progress, setProgress] = useState({ progress: 0, step: "Starting..." });
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5001';
  const navigate = useNavigate();
  const location = useLocation();
  const containerRef = useRef(null); // Reference to the container for calculating width
  const token = location.state?.token; // Retrieve the token from navigation state
  // const token = 'eyJhbGciOiJIUzI1NiIsImtpZCI6ImxIdDRlU25SNnU1MXhyRVciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3JicmJsc2J2cGl4aHZnd3Fsaml5LnN1cGFiYXNlLmNvL2F1dGgvdjEiLCJzdWIiOiI5NDA2MGEwMC1jMmE2LTQwNGYtOTkzNC1iZTU2YmRlNjIxMTIiLCJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNzMyMTIxNzgxLCJpYXQiOjE3MzIxMTE3ODEsImVtYWlsIjoiYXJuYXVkLmNpc3NlQG9yYW5nZS5mciIsInBob25lIjoiIiwiYXBwX21ldGFkYXRhIjp7InByb3ZpZGVyIjoiZW1haWwiLCJwcm92aWRlcnMiOlsiZW1haWwiXX0sInVzZXJfbWV0YWRhdGEiOnsiZW1haWwiOiJhcm5hdWQuY2lzc2VAb3JhbmdlLmZyIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmllZCI6ZmFsc2UsInN1YiI6Ijk0MDYwYTAwLWMyYTYtNDA0Zi05OTM0LWJlNTZiZGU2MjExMiJ9LCJyb2xlIjoiYXV0aGVudGljYXRlZCIsImFhbCI6ImFhbDEiLCJhbXIiOlt7Im1ldGhvZCI6Im90cCIsInRpbWVzdGFtcCI6MTczMjExMTc4MX1dLCJzZXNzaW9uX2lkIjoiNzgwMzk1ZDctYzY1Ny00NzdkLWFhNDgtNGExOGRmZmEyY2JlIiwiaXNfYW5vbnltb3VzIjpmYWxzZX0.l0GKjEVPmnVJtOe_DMTHYSi5au9p6sFZ4e7M3gevuP8';

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);
  
    const parts = [];
    if (hours > 0) parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    if (minutes > 0) parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
    if (seconds > 0 || parts.length === 0) parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);

    console.log(seconds)
  
    return parts.join(', ');
  };  

  useEffect(() => {
    if (!token) {
      console.error('No token found. Redirecting to login...');
      navigate('/'); // Redirect to login page if token is missing
    } else {
      console.log('Token received in Dashboard:', token);
      // Use the token for fetching user data, API calls, etc.
    }
  }, [token, navigate]);

  // Fetch the authenticated user and credits
  useEffect(() => {
    console.log("Fetching user, credits, and files...");
  
    // Function to fetch user data
    const fetchUser = async () => {
      console.log("Fetching user...");
      try {
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) {
          console.error('Error fetching user:', error);
          return;
        }
        setUser(user);
      } catch (err) {
        console.error('Error fetching user:', err);
      }
    };
  
    // If not test user, fetch user and then credits
    const fetchUserAndData = async () => {
      try {
        // Step 1: Fetch user and wait for it to complete
        await fetchUser(setUser);
  
        // Step 2: Fetch credits and files concurrently
        await Promise.all([
          fetchCredits(),
          fetchFiles()
        ]);
      } catch (error) {
        console.error("Error fetching user and related data:", error);
      }
    };
    
    fetchUserAndData();
  }, [navigate, token]);

  // Function to fetch user's remaining credits
  const fetchCredits = async () => {
    try {
      const response = await fetch(`${backendUrl}/get_user_credits`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      if (response.ok) {
        setUserCredits(data.user_credits);
        console.log('user credits', data.user_credits);

        // Augment the user object
        setUser((prevUser) => ({
          ...prevUser,
          credits: data.user_credits, // Add credits to user object
        }));

      } else {
        console.error('Error fetching credits:', data.error);
      }
    } catch (error) {
      console.error('Error fetching credits:', error);
    }
  };

  // useEffect(() => {
  //   fetchCredits();
  // }, []);


  // Fetch user files
  const fetchFiles = async () => {

    console.log("fetchFiles")
    try {
      const response = await fetch(`${backendUrl}/get_user_files`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Fetched files:", data);
        console.log(data)
  
        // Automatically select the first file if available
        if (data.length > 0) {
                  // Extract unique IDs from the retrieved files
          // const filenames = data.map(file => file.file_name);
          // const ids = data.map(file => file.id);
          // console.log("Unique Ids:", ids);
          // console.log("Unique Filenames:", filenames);
  
          // Update the state variable
          setUserFiles(data)
          setSelectedFile(data[0]); // Set first file as selectedFile
        }
  
      } else {
        console.error("Error fetching files:", data.error);
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };
  
  const onLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      setUser(null);
      navigate('/'); // Redirect to landing after logout
    } else {
      console.error('Logout failed:', error.message);
    }
  };

  // Handler for selecting a unique ID from the dropdown
  const handleSelect = (file) => {
    setSelectedFile(file);
  };

  // Fetch predictions and average scores when selectedId changes
  useEffect(() => {
    console.log(selectedFile)
    if (!selectedFile || !user.email) return; // Ensure both selectedId and user email are present

    const fetchData = async () => {
      setIsLoading(true); // Set loading to true when fetching starts
      
      try {
    // Replace with actual token retrieval when ready
        // const token = 'eyJhbGciOiJIUzI1NiIsImtpZCI6ImxIdDRlU25SNnU1MXhyRVciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3JicmJsc2J2cGl4aHZnd3Fsaml5LnN1cGFiYXNlLmNvL2F1dGgvdjEiLCJzdWIiOiI5NDA2MGEwMC1jMmE2LTQwNGYtOTkzNC1iZTU2YmRlNjIxMTIiLCJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNzMyMTIxNzgxLCJpYXQiOjE3MzIxMTE3ODEsImVtYWlsIjoiYXJuYXVkLmNpc3NlQG9yYW5nZS5mciIsInBob25lIjoiIiwiYXBwX21ldGFkYXRhIjp7InByb3ZpZGVyIjoiZW1haWwiLCJwcm92aWRlcnMiOlsiZW1haWwiXX0sInVzZXJfbWV0YWRhdGEiOnsiZW1haWwiOiJhcm5hdWQuY2lzc2VAb3JhbmdlLmZyIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV92ZXJpZmllZCI6ZmFsc2UsInN1YiI6Ijk0MDYwYTAwLWMyYTYtNDA0Zi05OTM0LWJlNTZiZGU2MjExMiJ9LCJyb2xlIjoiYXV0aGVudGljYXRlZCIsImFhbCI6ImFhbDEiLCJhbXIiOlt7Im1ldGhvZCI6Im90cCIsInRpbWVzdGFtcCI6MTczMjExMTc4MX1dLCJzZXNzaW9uX2lkIjoiNzgwMzk1ZDctYzY1Ny00NzdkLWFhNDgtNGExOGRmZmEyY2JlIiwiaXNfYW5vbnltb3VzIjpmYWxzZX0.l0GKjEVPmnVJtOe_DMTHYSi5au9p6sFZ4e7M3gevuP8';
        
        //TODO to update here to use selectedFile
        const selectedId = selectedFile.id
        // Fetch predictions
        const predictionsResponse = await fetch(`${backendUrl}/get_predictions/${selectedId.trim()}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        const predictionsData = await predictionsResponse.json();
        

        if (predictionsResponse.ok) {
          console.log(predictionsData)
          console.log(predictionsData.predictions)
          setPredictions(predictionsData.predictions);
          console.log(predictionsData.topCategoryEmotions)
          setTopCategoryEmotions(predictionsData.topCategoryEmotions);
        }
        
        // let tempUser = null;
        
        // Fetch average scores
        const averagesResponse = await fetch(`${backendUrl}/get_average_scores/${selectedId.trim()}`,{
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        const averagesData = await averagesResponse.json();

        console.log(averagesData)

        if (averagesResponse.ok) {
          setAverageScoresCategory(averagesData.categories); // Each element has 'speaker' and 'categories'
          // setUser(tempUser)
        } else {
          setIsLoading(false); // Set loading to false when fetching is complete
          console.error('Error fetching average scores:', averagesData.error);
        }
      } catch (error) {
        setIsLoading(false); // Set loading to false when fetching is complete
        console.error('Error fetching data:', error);
      } finally{
        setIsLoading(false); // Set loading to false when fetching is complete
      }
    };

    fetchData();
  }, [selectedFile]);

    // Function to calculate wheel size based on number of speakers
  const calculateWheelSize = () => {
    if (!containerRef.current) return 300; // Default size

    const containerWidth = containerRef.current.offsetWidth;
    const numberOfSpeakers = averageScoresCategory.length || 1; // Avoid division by zero
    const padding = 40; // Space between wheels

    // Calculate size: (containerWidth - total padding) / numberOfSpeakers
    const size = Math.min((containerWidth - (numberOfSpeakers + 1) * padding) / numberOfSpeakers, 300); // Cap size at 300px
    console.log(size)

    return size;
  };

  const [wheelSize, setWheelSize] = useState(calculateWheelSize());

  // Update wheel size on window resize
  useEffect(() => {
    const handleResize = () => {
      setWheelSize(calculateWheelSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [averageScoresCategory]);

  const handleRename = async (oldName, newName) => {
    try {
      // Get the access token from Supabase authentication
      // const { data: { session } } = await supabase.auth.getSession();
      // const token = session?.access_token;

      console.log(selectedFile)

      setIsRenaming(true); // Start spinner

      if (!token) {
        console.error('No access token found');
        setIsRenaming(false); // Stop spinner
        // Handle error appropriately
        return;
      }

      const response = await fetch(`${backendUrl}/rename_upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          oldName,
          newName
        })
      });

      const data = await response.json();

      if (response.ok) {
      // Update the userFiles state with the new name
      setUserFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.file_name === oldName ? { ...file, file_name: newName } : file
        )
      );

      // Update selectedFile if it was the renamed one
      if (selectedFile?.file_name === oldName) {
        setSelectedFile((prevSelectedFile) => ({
          ...prevSelectedFile,
          file_name: newName,
        }));
      }

        showNotification('Upload renamed successfully.', 'success');
      } else {
        console.error('Error renaming upload:', data.error);
        showNotification(data.error || 'Failed to rename upload.', 'error');
      }
    } catch (error) {
      console.error('Error renaming upload:', error);
      showNotification('An error occurred while renaming the upload.', 'error');
    } finally {
      setIsRenaming(false); // Stop spinner
    }
      
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 3000); // Auto-hide after 3 seconds
  };

  const handleAnalyseClick = async (file) => {
    try {
      // First, check if a file is already being processed
      const response = await fetch(`${backendUrl}/check_file_processing`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      const data = await response.json();
      if (response.ok) {
        if (data.has_processing_file) {
          // Show a popup informing the user
          setPopupContent({
            title: 'Processing in Progress',
            message:
              'You already have a file being processed. Please wait until it completes before uploading a new file.',
            isError: true,
            onConfirm: () => setIsPopupVisible(false), // Close the popup
          });
          setIsPopupError(true);
          setIsPopupVisible(true);
          return;
        } else {
          // Proceed with the existing logic
          await proceedWithAnalysis(file);
        }
      } else {
        // Handle error
        console.error('Error checking processing files:', data.error);
        showNotification(
          'An error occurred while checking processing files.',
          'error'
        );
      }
    } catch (error) {
      console.error('Error checking processing files:', error);
      showNotification(
        'An error occurred while checking processing files.',
        'error'
      );
    }
  };
  
  const proceedWithAnalysis = async (file) => {
    console.log(file);
    console.log('entering here');
    setSelectedNewRecording(file);
  
    // Fetch the file duration
    const duration = await getFileDuration(file);
    const durationInMinutes = parseFloat((duration / 60).toFixed(2));
    setFileDuration(durationInMinutes);
  
    const creditsNeeded = parseFloat(durationInMinutes.toFixed(2)); // Assuming 1 credit per minute
  
    if (userCredits >= creditsNeeded) {
      setPopupContent({
        title: 'Confirm the upload',
        message: `The file duration is ${formatDuration(
          duration
        )}: ${creditsNeeded} credits. \n Are you sure you want to proceed?`,
        isError: false,
        onConfirm: () => {
          setIsPopupVisible(false); // Close the popup after confirmation
          confirmAnalysis(durationInMinutes);
        },
      });
      setIsPopupError(false); // Reset the error flag
    } else {
      setPopupContent({
        title: 'Insufficient Credits available',
        message: `The file duration is ${formatDuration(
          duration
        )}: ${creditsNeeded} credits. \n You don't have enough credits to process this file`,
        isError: true,
        onConfirm: () => setIsPopupVisible(false), // Single "Understood" button
      });
      setIsPopupError(true); // Flag as insufficient credit popup
    }
  
    setIsPopupVisible(true); // Show the popup
  };
  
  // Function to get file duration (you need to implement this)
  const getFileDuration = async (file) => {
    return new Promise((resolve, reject) => {
      const audio = document.createElement('audio');
      audio.src = URL.createObjectURL(file);
  
      // Handle metadata loading
      audio.addEventListener('loadedmetadata', () => {
        resolve(audio.duration); // Return duration in seconds
        console.log("getfileduration success")
        console.log("duration is " + audio.duration)
      });
  
      // Handle errors
      audio.addEventListener('error', () => {
        reject(new Error('Unable to load audio file metadata.'));
        console.log("getfileduration error")
      });
    });
  };
  
  // Confirm analysis and proceed with upload and processing
  const confirmAnalysis = async (duration) => {
    setIsPopupVisible(false); // Hide the popup
    setIsAnalyzing(true); // Show the spinner

    console.log(selectedNewRecording)
    if (!selectedNewRecording) {
      setIsAnalyzing(false); // Hide spinner if there's an error
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedNewRecording);
    formData.append('username', user.email); 
    formData.append('duration', duration); // Include username if needed

    try {
      console.log('Uploading and processing file...')
      showNotification('Uploading and processing file...', 'info');

      pollProgress(); // Start polling for progress


      const response = await fetch(`${backendUrl}/upload`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
      console.log(data)
      console.log(data.file)

      if (response.ok) {
        // const uniqueId = data.unique_id; // This is the file ID

        // const saveResponse = await saveFileToDatabase(
        //   data.unique_id, 
        //   data.unique_id, 
        //   duration, 
        //   user.email, 
        //   duration
        // );

        // console.log(data)
        // console.log(saveResponse)
        // console.log(saveResponse.data)

        fetchCredits(); // Update credits after processing  
        setUserFiles((prevFiles) => [...prevFiles, data.file]);
        setSelectedFile(data.file);
        showNotification('File processed successfully.', 'success');
  
      } else {
        showNotification(`Error: ${data.error}`, 'error');
      }
    } catch (error) {
      console.error('Error during file analysis:', error);
      showNotification('An error occurred while uploading the file.', 'error');
    } finally {
      setIsAnalyzing(false); // Hide spinner when finished
    }

  };

  // const saveFileToDatabase = async (fileId, filename, duration, userEmail, file_credits) => {
    
  //   try {
  //     // console.log(fileI)
  //     const response = await fetch(`${backendUrl}/save_file_record`, {
  //       method: 'POST',
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         file_id: fileId,
  //         file_name: filename,
  //         file_duration: duration,
  //         username: userEmail,
  //         file_credits: file_credits,
  //       }),
  //     });
  
  //     const data = await response.json();
  
  //     if (response.ok) {
  //       console.log('File record saved to the database:', data);
  //       return { success: true, data }; // Indicate success and return data
  //     } else {
  //       console.error('Error saving file record:', data.error);
  //       showNotification(`Error saving file record: ${data.error}`, 'error');
  //       return { success: false, data: {} }; // Indicate failure and return empty data
  //     }
  //   } catch (error) {
  //     console.error('Unexpected error while saving file record:', error);
  //     showNotification('An unexpected error occurred while saving the file record.', 'error');
  //     return { success: false, data: {} }; // Indicate failure and return empty data
  //   }
  // };
  
  const pollProgress = async () => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(`${backendUrl}/get_progress/`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const data = await response.json();
        setProgress(data);
  
        if (data.progress === 1) {
          clearInterval(interval); // Stop polling when progress is complete
          setIsAnalyzing(false); // Hide spinner
          setProgress({ progress: 0, step: "Starting..." }); // Reset progress
        }
      } catch (error) {
        console.error('Error fetching progress:', error);
        clearInterval(interval); // Stop polling on error
      }
    }, 1000); // Poll every second
  };
  
  // Check if the user is on a mobile device
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700); // Update the state based on screen width
    };

    // Run on mount and on window resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  return (
    <>
      {isMobile && (
        <div className="mobile-warning">
          For the best experience, please open on desktop
        </div>
      )}
      {isAnalyzing && (
        <div className="spinner-overlay">
          <div className="progress-container">
            <p>{progress.step}</p>
            <div className="progress-bar">
              <div
                className="progress-bar-inner"
                style={{ width: `${(progress.progress)*100}%` }}
              ></div>
            </div>
          </div>
          <div className="spinner"></div>
        </div>
      )}
      <Navbar user={user} onLogout={onLogout} />
      <div className="notification-container">
        {notification.message && (
          <div className={`notification ${notification.type}`}>
            {notification.message}
          </div>
        )}
      </div>
      <div className="dashboard-container">
        <div className = 'top-container'>
          {/* File Upload Section */}
          <div className="file-upload-container">
            <FileUpload 
              onAnalyseClick={handleAnalyseClick} 
              selectedNewRecording={selectedNewRecording}
              setSelectedNewRecording={setSelectedNewRecording}
            />
            {userFiles.length > 0 ? (
              <FileDropdown
                userFiles={userFiles}
                selectedFile={selectedFile}
                onSelect={handleSelect}
                onRename={handleRename}  
                isRenaming={isRenaming}          
              />
            ) : (
              <p>No previous uploads. Please upload a file.</p>
            )}
          </div>

          {/* Confirmation Popup */}
          {isPopupVisible && (
          <Popup
            title={popupContent.title}
            message={popupContent.message}
            isError={isPopupError} 
            onConfirm={popupContent.onConfirm}
            onCancel={() => setIsPopupVisible(false)} // Close the popup
          />
          )}

          <div className="results-container">
            {selectedFile ? (
              isLoading ? (
                <div className="loading-container">
                  <div className="spinner"></div>
                  <p>Loading analysis results...</p>
                </div>
              ) : (
                <>
                  <h3>Overall file analysis</h3>
                  <div className="plutchik-row">
                    {averageScoresCategory.length > 0 ? (
                      averageScoresCategory.map((speakerData, index) => (
                        <div className="plutchik-container" key={speakerData.speaker || index}>
                          <p>{speakerData.speaker === 'all' ? 'All speakers combined' : speakerData.speaker}</p>
                          <PlutchikWheel
                            topCategoryEmotions={speakerData.categories}
                            speaker={speakerData.speaker}
                            size={wheelSize}
                            textSize={0.046}
                          />
                        </div>
                      ))
                    ) : (
                      <p>Average scores will display here after analysis.</p>
                    )}
                  </div>
                </>
              )
            ) : (
              <p>Please upload a file to view emotions.</p>
            )}
          </div>
        </div>

        {/* Emotion Player Section */}
        {selectedFile ? (
          isLoading ? (
            // Show a loading spinner while data is being fetched
            <div className="loading-container">
              <div className="spinner"></div>
              <p>Loading data for the selected file...</p>
            </div>
          ) : (
            predictions.length && topCategoryEmotions.length && averageScoresCategory.length ? (
              // Render EmotionPlayer only when data is ready
              <EmotionPlayer
                uniqueId={selectedFile.id}
                predictions={predictions}
                topCategoryEmotions={topCategoryEmotions} // Pass topCategoryEmotions as a prop
                token = {token}
              />
            ) : (
              <p>Data unavailable for the selected file.</p>
            )
          )
        ) : (
          <p>Please upload a file to view emotions.</p>
        )}
      </div>
    </>
  );
}

export default Dashboard;
