// src/components/UniqueIdDropdown/UniqueIdDropdown.js

import React, { useState } from 'react';
import './FileDropdown.css';
import editRoundIcon from '../../assets/edit-round-icon.svg'; // Import the SVG icon

function FileDropdown({ userFiles = [], selectedFile, onSelect, onRename, isRenaming }) {

  // console.log(userFiles)
  // console.log(selectedFile)
  // const [isRenaming, setIsRenaming] = useState(false);
  const [newName, setNewName] = useState('');
  const [isEditing, setIsEditing] = useState(false); // Local state for rename input visibility

  const handleChange = (e) => {
    const selectedFileName = e.target.value;
    const selectedFileObject = userFiles.find((file) => file.file_name === selectedFileName);
    console.log(selectedFileObject)
    console.log(selectedFileObject.file_name)
    onSelect(selectedFileObject); // Notify parent component of the new selection
  };

  const handleRenameClick = () => {
    console.log('Rename button clicked');
    setIsEditing(true);
    setNewName(''); // Initialize with current name
  };

  const handleRenameCancel = () => {
    setIsEditing(false);
    setNewName('');
  };

  const handleRenameSave = () => {
    console.log(selectedFile)
    if (newName && newName !== selectedFile.file_name) {
      onRename(selectedFile.file_name, newName); // Notify parent component to handle renaming
      setIsEditing(false);
    }
  };

  return (
    <div className="dropdown-container">
      {isRenaming && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <label htmlFor="unique-id-dropdown">Or choose previous:</label>
      <div className={`dropdown-wrapper ${isRenaming ? 'disabled' : ''}`}>
        <select
          id="file-dropdown"
          value={selectedFile?.file_name || ''}
          onChange={handleChange}
          disabled={isRenaming || isEditing} // Disable during renaming or editing
        >
        {userFiles.map((file) => (
          <option key={file.id} value={file.file_name}>
            {file.file_name}
          </option>
        ))}
        </select>
        <span
          className="rename-icon"
          title="Use this to rename this upload"
          onClick={handleRenameClick}
        >
          <img src={editRoundIcon} alt="Edit" /> {/* Use the SVG icon */}
        </span>
      </div>
      {isEditing && (
        <div className="rename-container">
          <label htmlFor="new-name-input" className="rename-label">New name</label> {/* Added label */}
          <input
            id="new-name-input" // Added id for accessibility
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Enter new name"
            className="rename-input"
            disabled={isRenaming} // Disable input during renaming
          />
          <div className="rename-buttons">
            <button onClick={handleRenameSave} disabled={isRenaming}>Save</button>
            <button onClick={handleRenameCancel} disabled={isRenaming}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default FileDropdown;
