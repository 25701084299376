// src/components/Login/Login.js
import React, { useState, useEffect } from 'react';
import supabase from '../../supabaseClient'; // Adjust the path based on your file structure
import './Login.css'; // Import the CSS file

function Login() {
  const [email, setEmail] = useState('');
  const [buttonText, setButtonText] = useState('Continue');
  const [isDisabled, setIsDisabled] = useState(false);
  const [timer, setTimer] = useState(10); // Timer set to 10 seconds
  const [message, setMessage] = useState(null); // To display success/error messages

  const frontend_link = 'https://emofind.com'
  // const frontend_link = 'http://localhost:3000'

  const handleContinue = async (e) => {
    e.preventDefault();

    if (!email) {
      setMessage({ type: 'error', text: 'Please enter your email.' });
      return;
    }

    // Send the magic link using Supabase
    console.log(supabase)

    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: `${frontend_link}/auth/callback`, // Replace with your redirect URL
      },
    });

    if (error) {
      setMessage({ type: 'error', text: error.message });
    } else {
      setMessage({
        type: 'success',
        text: 'Magic link sent! Please check your email to log in (check the spam folder)',
      });
    //   setButtonText('Click the link sent to your email');
      setIsDisabled(true);
      setTimer(60); // Start the timer at 10 seconds
    }
  };

  useEffect(() => {
    let interval = null;
    if (isDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsDisabled(false);
      setButtonText('Continue');
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isDisabled, timer]);

  return (
    <div className="login-container">
      <p className="login-title">Try for free now, add your email</p>
      <form className="form-element" onSubmit={handleContinue}>
        <input
          type="email"
          placeholder="Type your email..."
          className="username-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button
          type="submit"
          className="login-button"
          disabled={isDisabled}
        >
          {buttonText}
        </button>
      </form>
      {isDisabled && (
        <p>You can send a new login link in {timer} seconds.</p>
      )}
      {message && (
        <p className={`message ${message.type}`}>{message.text}</p>
      )}
    </div>
  );
}

export default Login;
