// src/components/FileUpload/FileUpload.js

import React, { useState } from 'react';
import './FileUpload.css';

// function FileUpload({ onUploadSuccess, username }) {
function FileUpload({ onAnalyseClick, selectedNewRecording, setSelectedNewRecording }) {


  const handleFileChange = (e) => {
    const selectedFileUpload = e.target.files[0];
    setSelectedNewRecording(selectedFileUpload); // Only update the state
  };

  const handleAnalyseClick = (e) => {
    e.preventDefault();
    if (!selectedNewRecording) {
      alert('Please select a file to upload.');
      return;
    }
    onAnalyseClick(selectedNewRecording); // Notify parent component with the selected file
  };

  return (
    <div className="form-file">
      <form className="file-upload-form">
        <label className="custom-file-upload">
          Choose File (audio/video)
          <input
            type="file"
            accept=".mp4,.wav,.mp3,.m4a"
            onChange={handleFileChange}
          />
        </label>

        <button
          type="button"
          disabled={!selectedNewRecording}
          onClick={handleAnalyseClick}
        >
          Analyse
        </button>
      </form>
      {selectedNewRecording && 
      <p className="selected-file-name">
        Selected file: {selectedNewRecording.name}
      </p>}
    </div>
  );
}

export default FileUpload;


//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!file) {
//       setMessage('Please select a file to upload.');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('username', username); // Add the username to the form data


//     try {
//       setMessage('Uploading and processing file...');
//       const response = await fetch(`${backendUrl}/upload`, {
//         method: 'POST',
//         body: formData,
//       });

//       const data = await response.json();

//       if (response.ok) {
//         setMessage(`File processed successfully. Unique ID: ${data.unique_id}`);
//         onUploadSuccess(data.unique_id);
//       } else {
//         setMessage(`Error: ${data.error}`);
//       }
//     } catch (error) {
//       console.error('An error occurred:', error);
//       setMessage('An error occurred while uploading the file.');
//     }
//   };

//   return (
//     <>
//       {/* <h2>Upload File</h2> */}
      
//       <div className='form-file'>
//         <form onSubmit={handleSubmit} className="file-upload-form">
//           <label className="custom-file-upload">
//             Choose File
//             <input
//                 type="file"
//                 accept=".mp4,.wav,.mp3,.m4a"
//                 onChange={handleFileChange}
//             />
//           </label>
          
//           <button 
//             type="submit"
//             disabled={!file}
//           >
//               Analyse
//           </button>

//         </form>
//         {file && <p className="selected-file-name">Selected file: {file.name}</p>}


//         </div>
//       {/* <p className="upload-message">{message}</p> */}
//     </>
//   );
// }

// export default FileUpload;
